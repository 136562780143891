@import "./../../../../../../styles/dashboard/variables.scss";

#ShareTrackingTransport {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;

  .TrailerInfoCard {
    display: flex;
    padding: 12px 16px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    gap: 12px;
    border-radius: 4px;
    border: 1px solid #ced4da;

    .TrailerInfoCardMain {
      display: flex;
      align-items: center;
      gap: 16px;

      .TrailerInfoCardDesc {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 4px;
        flex: 1 0 0;

        .TrailerInfoTypeText {
          color: #212529;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 16px;

          span {
            color: #878a99;
          }
        }
        .TrailerInfoTransport {
          display: flex;
          padding: 4px 8px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          border-radius: 4px;
          border: 1px solid #e9ebec;
          background: #f3f6f9;

          .TrailerInfoTransportText {
            color: #4a5056;
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
          }
        }
      }
    }

    .TrailerInfoCardTransport {
      display: flex;
      align-items: center;
      gap: 4px;
      cursor: pointer;

      p {
        color: #3577f1;
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
      }

      svg {
        font-size: 16px;
        color: #405189;
      }
    }
  }
  .takenOrderText {
    color: #878a99;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    span {
      color: #405189;
    }
  }
}

.ShareTrackingTransportTooltip {
  box-shadow: 0px 6.25px 20px 0px rgba(0, 0, 0, 0.13);
  background: $secondary0 !important;
  border-radius: $borderRadius;
  display: inline-flex;
  min-width: 200px;
  padding: 0 !important;
  .list {
    display: flex;
    flex-direction: column;
    .TrailerInfoCardMain {
      display: flex;
      align-items: center;
      gap: 16px;
      border-bottom: 1px solid #e9ebec;
      padding: 8px 16px;
      .TrailerInfoCardDesc {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 4px;
        flex: 1 0 0;
        .TrailerInfoTypeText {
          color: #212529;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 16px;
          span {
            color: #878a99;
          }
        }
        .TrailerInfoTransport {
          display: flex;
          padding: 4px 8px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          border-radius: 4px;
          border: 1px solid #e9ebec;
          background: #f3f6f9;
          .TrailerInfoTransportText {
            color: #4a5056;
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
          }
        }
      }
    }
  }
}
