@import "../../../../../styles/dashboard/variables.scss";

#LoadBoardsActionField {
  vertical-align: middle;
  padding: 12px 10px;
  .LoadBoardsActionFieldcontent {
    min-width: 80px;
    #DButton {
      button {
        width: 100%;
      }
    }
    .bidContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 12px;
      p {
        color: $secondary200;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        span {
          color: $primary500;
          font-weight: 600;
        }
      }
      #DTextButton {
        button {
          svg {
            font-size: 20px;
            color: $info;
          }
        }
      }
    }
  }
}
