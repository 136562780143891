@import "../../styles/dashboard/variables.scss";

#SubscribersFilters {
  width: 100%;
  padding: 12px 0;
  border-top: 1px solid $secondary50;
  border-bottom: 1px solid $secondary50;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 4px;
  .filters {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 12px;
    .detailSearchButton {
      display: flex;
      align-items: center;
      padding: 8px 12px;
      gap: 8px;

      border-radius: 32px;
      border: 1px solid $secondary50;
      color: $secondary100;
      cursor: pointer;
      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          font-size: 16px;
        }
      }
      p {
        color: $secondary200;
        font-size: 14px;
        font-weight: 600;
      }
    }
  }
  .clear {
    padding-top: 8px;
    #DTextButton {
      button {
        white-space: nowrap;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0em;
        color: $info;
      }
    }
  }
}

@media screen and (max-width: $mediaMedium) {
  #SubscribersFilters {
    .filters {
      display: none;
    }
    .clear {
      display: none;
    }
  }
}
