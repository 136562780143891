@import "./../../../../../styles/dashboard/variables.scss";

#ShareDetailContentStatusUpdates {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #e9ebec;

  .ShareDetailContentStatusUpdatesHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    gap: 12;

    .title {
      color: #000;
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }
    .btn {
      display: flex;
      padding: 4px 8px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 4px;
      border: 1px solid #3577f1;
      background: rgba(215, 228, 252, 0.25);
      cursor: pointer;

      .btnTitle {
        color: #3577f1;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
      }
      svg {
        color: #3577f1;
        font-size: 16px;
      }
    }
  }
  .ShareDetailContentStatusUpdatesList {
    width: 100%;
    height: 320px;
    padding-top: 16px;
    background: #fff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.03) inset;
    overflow: hidden;

    .ShareDetailContentStatusUpdatesListWrapper {
      display: flex;
      flex-direction: column;
      gap: 32px;
      flex-grow: 1;
      max-height: calc(320px - 16px);
      overflow-y: auto;
      min-height: 100%;
      padding-right: 16px;
    }
  }
}
