@import "./../../../../../styles/dashboard/variables.scss";

#ShareDetailContentHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  gap: 12px;

  .left {
    h3 {
      color: #212529;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }
    .salemanager {
      display: flex;
      align-items: center;
      gap: 4px;
      align-self: stretch;

      svg {
        font-size: 20px;
        color: #878a99;
      }

      h4 {
        color: #4a5056;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
    }
  }

  .right {
    p {
      color: #405189;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }
  }
}

@media only screen and (max-width: $mediaSmall) {
  #ShareDetailContentHeader {
  }
}
