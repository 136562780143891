@import "./../../../../styles/landing/variables.scss";

#SendAgain.SendAgain {
  display: flex;
  justify-content: center;
  user-select: none;
  .SendAgainContent {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    p {
      text-align: center;
      color: $primaryBrand;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      letter-spacing: 0.32px;
      position: relative;
    }
    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 4px;
      svg {
        color: $primaryBrand;
        font-size: 20px;
      }
      &.spinning {
        animation: 0.5s linear 0s infinite alternate spin;
      }
    }
  }
  .check {
    width: 24px;
    height: 24px;
    .path {
      stroke-dasharray: 1000;
      stroke-dashoffset: 0;
      &.circle {
        -webkit-animation: dash 0.9s ease-in-out;
        animation: dash 0.9s ease-in-out;
      }
      &.line {
        stroke-dashoffset: 1000;
        -webkit-animation: dash 0.9s 0.35s ease-in-out forwards;
        animation: dash 0.9s 0.35s ease-in-out forwards;
      }
      &.check {
        stroke-dashoffset: -100;
        -webkit-animation: dash-check 0.9s 0.35s ease-in-out forwards;
        animation: dash-check 0.9s 0.35s ease-in-out forwards;
      }
    }

    @-webkit-keyframes dash {
      0% {
        stroke-dashoffset: 1000;
      }
      100% {
        stroke-dashoffset: 0;
      }
    }

    @keyframes dash {
      0% {
        stroke-dashoffset: 1000;
      }
      100% {
        stroke-dashoffset: 0;
      }
    }

    @-webkit-keyframes dash-check {
      0% {
        stroke-dashoffset: -100;
      }
      100% {
        stroke-dashoffset: 900;
      }
    }

    @keyframes dash-check {
      0% {
        stroke-dashoffset: -100;
      }
      100% {
        stroke-dashoffset: 900;
      }
    }
  }
}
