.unsubscribeButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  button {
    border: 2px solid #000;
    color: #000;
    padding: 8px 12px;
    outline: none;
    cursor: pointer;
    border: none;
    font-family: "SourceSansPro";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    border-radius: 6px;
    transition: 0.2s;
    background: transparent;
    width: fit-content;
    margin-top: 24px;
  }
  .unsBtn {
    color: #da1414;
    border: 2px solid #da1414;
    &:hover {
      color: #fff;
      background: #da1414;
    }
  }

  .reSubBtn {
    color: #1a2023;
    border: 2px solid #1a2023;
    &:hover {
      color: #fff;
      background: #1a2023;
    }
  }
}
