@import "./../../styles/dashboard/variables.scss";

#ShareSidebar {
  width: 100%;
  height: calc(100vh - 80px);
  min-height: 400px;
  overflow: hidden;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  border-right: 2px solid $secondary50;
  box-shadow: 0px 6.25px 20px 0px rgba(0, 0, 0, 0.13);
  background-color: #ffffff;

  background-color: "orange";
}

// @media only screen and (max-width: $mediaLarge) {
//   #ShareSidebar {
//     display: none;
//   }
// }
