@import "../../styles/dashboard/variables.scss";

@font-face {
  font-family: SourceSansPro;
  src: url("../../../public/assets/fonts/SourceSansPro/SourceSans3.woff");
  src: url("../../../public/assets/fonts/SourceSansPro/SourceSans3.woff2");
  src: url("../../../public/assets/fonts/SourceSansPro/SourceSans3.ttf");
}

#SubscribersHeader {
  font-family: SourceSansPro;
  position: fixed;
  z-index: 10;
  width: 100%;
  height: 80px;
  background-color: $secondary0;
  box-shadow: 0px 2px 4px 0px rgba(50, 49, 94, 0.1);
  top: 0;
  transition: top 0.3s ease-in-out;
  .SubscribersHeaderContainer {
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    .leftContainer {
      display: flex;
      align-items: center;
      gap: 16px;
      .fullLogo {
        cursor: pointer;
      }
      .signUpSuggestionContainer {
        display: flex;
        padding: 12px 16px;
        justify-content: center;
        align-items: center;
        gap: 24px;
        border-radius: 12px;
        background: linear-gradient(264deg, $primary100 0%, $primary50 99.47%);
        .group {
          display: flex;
          align-items: center;
          gap: 8px;
          h3 {
            color: $primary900;
            font-size: 20px;
            font-weight: 700;
          }
          .badge {
            display: flex;
            padding: 4px 12px;
            align-items: center;
            gap: 8px;

            border-radius: 41px;
            border: 1px solid $info;
            background: $secondary0;
          }
        }
      }
    }
    .rightContainer {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 12px;
      .profileButton {
        display: flex;
        padding: 8px 16px;
        align-items: center;
        gap: 8px;

        border-radius: 24px;
        border: 1px solid $secondary75;
        cursor: pointer;
        .icon {
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 100%;
          img {
            width: 24px;
            height: 24px;
          }
        }
        p {
          color: $secondary300;
          font-size: 16px;
          font-weight: 600;
        }
      }
    }
  }
}

@media only screen and (max-width: $mediaLarge) {
  #SubscribersHeader {
    .SubscribersHeaderContainer {
      .rightContainer {
        #Lang.Lang {
          display: block;
        }
        .profileButton {
          padding: 0;
          p {
            display: none;
          }
          .icon {
            img {
              width: 40px;
              height: 40px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $mediaMedium) {
  #SubscribersHeader {
    .SubscribersHeaderContainer {
      .leftContainer {
        .signUpSuggestionContainer {
          display: none;
        }
      }
    }
  }
}
