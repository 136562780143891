@import "../../../../styles/dashboard/variables.scss";

#EditPhoneModal {
  display: flex;
  flex-direction: column;
  gap: 12px;

  p {
    color: $secondary200;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
  .step2Container {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: center;
    justify-content: center;
    p {
      max-width: 300px;
      text-align: center;
      color: $secondary200;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
  }
}
