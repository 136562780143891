@import "./../../../styles/dashboard/variables.scss";

#ShareDetailContent {
  display: flex;
  padding: 16px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid #ced4da;
  background: #fff;
}

@media only screen and (max-width: $mediaSmall) {
  #ShareDetailContent {
    padding: 12px;
    gap: 12px;
  }
}
