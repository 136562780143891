@import "../../../../styles/dashboard/variables.scss";

#SubscribersOrderListMobileItem {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  border-radius: 4px;
  border: 1px solid $secondary50;
  background: $secondary0;

  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15), 0px 1px 3px 1px rgba(0, 0, 0, 0.05);
  .topContainer {
    width: 100%;
    display: flex;
    padding: 12px 8px 8px 8px;
    flex-direction: column;
    align-items: flex-start;
    .routeContainer {
      width: 100%;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        top: 20px;
        left: 11px;
        height: calc(100% - 20px);
        z-index: 0;
        border-left: 2px dashed $secondary100;
      }
      .routeHeader {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: 12px;
        .from {
          display: flex;
          align-items: center;
          gap: 12px;
        }
        .sparklesIcon {
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            font-size: 16px;
            color: $violet;
          }
        }
      }
      .distance {
        display: inline-flex;
        padding: 2px 4px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        margin-left: 32px;
        margin-top: 12px;
        margin-bottom: 12px;

        border-radius: 4px;
        border: 1px solid var(--stroke, #ced4da);
        background: #fff;

        color: var(--Text-Light, #333);

        font-size: 11px;
        font-weight: 400;
        line-height: 16px;
      }
      .AddressDetailsItem {
        display: flex;
        gap: 8px;
        margin-bottom: 8px;
        align-items: center;
        .content {
          color: $secondary500;

          font-size: 14px;
          font-weight: 600;
          line-height: 20px;

          display: flex;
          align-items: center;
        }
        &:last-child {
          margin-bottom: 0;
        }
        .icon {
          z-index: 1;
          width: 24px;
          height: 24px;
          background-color: $secondary0;
          color: $secondary200;
          svg {
            font-size: 24px;
          }
        }
      }
    }
    .pickupDateContainer {
      width: 100%;
      display: flex;
      padding: 8px 12px;
      margin: 8px 0;
      align-items: center;
      gap: 10px;

      border-radius: 4px;
      background: $successBG;
      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          font-size: 16px;
          color: $secondary100;
        }
      }
      p {
        color: $secondary500;
        font-size: 13px;
        font-weight: 400;
        line-height: 20px;
        span {
          font-weight: 600;
        }
      }
    }
    .cargoDetailsContainer {
      width: 100%;
      display: flex;
      padding: 0px 12px 12px 12px;
      gap: 8px;
      .cargoDetails {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .title {
          color: #878a99;
          font-size: 13px;
          font-weight: 600;
          line-height: 20px;
        }
        .content {
          color: #212529;
          font-size: 13px;
          font-weight: 400;
          line-height: 20px;
        }
      }
    }
  }
  .offersContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px 12px;

    background: $infoBG;
    p {
      color: $info;
      font-size: 13px;
      font-weight: 400;
      line-height: 20px;
    }
  }
}
