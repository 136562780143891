@import "../../../styles/dashboard/variables.scss";

#SubscribersProfile {
  .SubscribersProfile-container {
    width: 100%;
    .personInfoBox {
      margin-bottom: 24px;
    }
    .signUpSuggestionContainer,
    .mobileOrdersList {
      display: none;
    }
    #SubscribersFilters {
      margin: 16px 0;
    }
  }
}

@media screen and (max-width: $mediaMedium) {
  #SubscribersProfile .SubscribersProfile-container {
    .tableContainer {
      display: none;
    }
    .mobileOrdersList {
      display: block;
    }
    .signUpSuggestionContainer {
      display: flex;
      padding: 12px 16px;
      justify-content: space-between;
      align-items: center;
      gap: 24px;
      border-radius: 12px;
      background: linear-gradient(264deg, $primary100 0%, $primary50 99.47%);
      margin-bottom: 12px;
      .group {
        display: flex;
        align-items: center;
        gap: 8px;
        h3 {
          color: $primary900;
          font-size: 20px;
          font-weight: 700;
        }
        .badge {
          display: flex;
          padding: 4px 12px;
          align-items: center;
          gap: 8px;

          border-radius: 41px;
          border: 1px solid $info;
          background: $secondary0;
        }
        #DButton {
          button {
            min-width: auto;
          }
        }
      }
    }
  }
}
