@import "../../../styles/dashboard/variables.scss";

@font-face {
  font-family: SourceSansPro;
  src: url("../../../../public//assets/fonts/SourceSansPro/SourceSans3.ttf");
  src: url("../../../../public//assets/fonts/SourceSansPro/SourceSans3.woff");
  src: url("../../../../public//assets/fonts/SourceSansPro/SourceSans3.woff2");
}

#SubscribeCargoComponent {
  display: flex;
  padding: 16px 24px;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  width: 100%;
  border-radius: 8px;
  border: 1px solid $secondary50;
  background: #fff;
  .subscribeCargoCard {
    width: 100%;

    .CardHeader {
      width: 100%;
      padding-bottom: 16px;
      border-bottom: 1px solid $secondary50;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;
    }
    .money {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 4px;
      border-radius: 34px;
      background: $secondary25;
      padding: 4px 12px;
      .icon svg {
        font-size: 12px;
        color: $secondary200;
      }
      h4 {
        color: $secondary200;
        font-family: "SourceSansPro";
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
      }
    }

    .CardBody {
      display: flex;
      flex-direction: column;
      width: 100%;
      .cargoInfo-1 {
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 24px;
      }
      .CInfo {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 16px 12px;
        display: flex;
        height: 52px;
        padding: 12px 16px;
        gap: 16px;
        border-radius: 8px;
        border: 1px solid $secondary50;
        background: #fff;
        &:first-child {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
        &:nth-child(2) {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
        p {
          font-family: "SourceSansPro";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
        }
      }
      .cargoInfo-2 {
        display: flex;
        flex-direction: column;
        gap: 4px;
        margin-bottom: 12px;
        p {
          font-family: "SourceSansPro";
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          color: $secondary300;
        }
        h3 {
          font-family: "SourceSansPro";
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
          color: $secondary300;
        }
      }
      .cargoInfo-3 {
        width: 100%;
        display: flex;
        margin-bottom: 8px;
        flex-direction: column;
        .Addinfo-top {
          display: flex;
          align-items: center;
          gap: 8px;
          margin-bottom: 5px;
          h2 {
            color: $secondary300;
            font-family: "SourceSansPro";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
          }
          .translateGoogleApi a {
            display: flex;
            align-items: center;
            gap: 4px;
            color: $info;
            font-family: "SourceSansPro";
            font-size: 13px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            svg {
              font-size: 16px;
            }
          }
        }
        .text {
          color: $secondary100;
          font-family: "SourceSansPro";
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          margin-bottom: 2px;
        }
      }
      .cargoInfo-4 {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 8px;
        h3 {
          color: $secondary300;
          font-family: "SourceSansPro";
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
        }
        .documentsList {
          display: flex;
          flex-wrap: wrap;
          gap: 8px;
          a {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: 12px 8px;
            gap: 8px;
            width: 180px;
            height: 36px;
            border-radius: 4px;
            border: 1px solid $info;
            background: $secondary25;
            span svg {
              stroke: #2e5aac;
              fill: #2e5aac;
              font-size: 20px;
            }
            p {
              width: 100%;
              color: $info;
              text-overflow: ellipsis;
              font-family: "SourceSansPro";
              font-size: 13px;
              font-style: normal;
              font-weight: 600;
              line-height: 20px;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              overflow: hidden;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 550px) {
  #SubscribeCargoComponent {
    padding: 16px;
  }
}
