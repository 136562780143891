@import "./../../../../../../styles/dashboard/variables.scss";

#ShareDetailContentStatusUpdatesItem {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;

  .icon {
    display: flex;
    padding: 8px;
    align-items: center;
    border-radius: 99px;
    background: #878a99;
    position: relative;

    svg {
      font-size: 14px;
      color: #ffffff;
    }

    .testarrow {
      height: 72px;
      width: 3px;
      border-radius: 8px;
      position: absolute;
      bottom: -80px;
      left: 50%;
      transform: translateX(-50%);
      background: #ced4da;
    }

    &.active {
      background: #00b56c;
      .testarrow {
        background: #00b56c;
      }
    }
  }

  .content {
    display: flex;
    width: 314px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;

    .top {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;

      .statusText {
        color: #212529;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
      }
      .textItem {
        color: #4a5056;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;

        span {
          font-weight: 500;
          color: #212529;
        }
      }
    }
    .time {
      color: #878a99;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }
}
