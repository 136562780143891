@import "../../../styles/dashboard/variables.scss";

@font-face {
  font-family: SourceSansPro;
  src: url("../../../../public//assets/fonts/SourceSansPro/SourceSans3.ttf");
  src: url("../../../../public//assets/fonts/SourceSansPro/SourceSans3.woff");
  src: url("../../../../public//assets/fonts/SourceSansPro/SourceSans3.woff2");
}

#SubscribeRouteComponent {
  display: flex;
  padding: 16px 24px;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  width: 100%;
  border-radius: 8px;
  border: 1px solid $secondary50;
  background: #fff;
  .SubscribeGeneralCard {
    width: 100%;
    .hand-p {
      padding-bottom: 10px;
    }
    .CardHeader {
      width: 100%;
      padding-bottom: 16px;
      border-bottom: 1px solid $secondary50;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;
    }
    .ftl {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 4px;
      border-radius: 34px;
      background: $infoBG;
      padding: 4px 12px;
      .icon svg {
        font-size: 12px;
        color: $info;
      }
      h4 {
        color: $info;
        font-family: "SourceSansPro";
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
      }
    }

    .CardBody {
      #AddressDetails2 {
        .titleContainer {
          display: none;
        }
        .items .from .title h3 {
          font-weight: 400;
          color: $secondary100;
        }
        .items .from .title .timeContainer .dateCont > div {
          color: #003f88;
        }
        .items .AddressDetailsItem2 .content {
          font-weight: 600;
          color: $secondary300;
        }
        // .items .AddressDetailsItem2 .icon {
        //   color: #287d3c;
        // }
        .items .to .title h3 {
          font-weight: 400;
          color: $secondary100;
        }
        .items .to .title .timeContainer .dateCont > div {
          color: #003f88;
        }
      }
    }
  }
}

@media only screen and (max-width: 550px) {
  #SubscribeRouteComponent {
    padding: 16px;
    .SubscribeGeneralCard .CardBody #AddressDetails2 .items .AddressDetailsItem2 .content {
      line-height: 20px;
    }
  }
}
