@import "../../../../../styles/dashboard/variables.scss";

#LoadBoardsTrailerRequirementField {
  vertical-align: middle;
  padding: 12px 10px;
  .LoadBoardsTrailerRequirementFieldcontent {
    min-width: 80px;
    p {
      color: $secondary300;
      font-size: 13px;
      font-weight: 400;
      line-height: 20px;
    }
  }
}
