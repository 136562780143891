@import "./../../../../../../styles/dashboard/variables.scss";

#ShareDetailContentStatusUpdatesNoTracking {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .ShareDetailContentStatusUpdatesNoTrackingcontent {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;

    max-width: 430px;

    .avatarImage {
      display: flex;
      align-items: center;
      justify-content: center;

      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      width: 250px;
      height: 200px;
    }

    h4 {
      color: #212529;
      text-align: center;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }

    h6 {
      color: #878a99;
      text-align: center;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }
  }
}
