@import "../../../styles/dashboard/variables.scss";

@font-face {
  font-family: SourceSansPro;
  src: url("./../../../../public/assets/fonts/SourceSansPro/SourceSans3.woff");
  src: url("./../../../../public/assets/fonts/SourceSansPro/SourceSans3.ttf");
  src: url("./../../../../public/assets/fonts/SourceSansPro/SourceSans3.woff2");
}

#SubscribersDetail {
  width: 100vw;
  height: 100%;
  font-family: "SourceSansPro";
}
.subscribeDetailMain {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 60px;
  .subscribeDetailContent {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    .subscribeLeftComponents {
      width: calc((100% / 2) - 8px);
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
    .subscribeRightComponents {
      width: calc((100% / 2) - 8px);
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }
}

@media only screen and (max-width: 1080px) {
  .subscribeDetailMain {
    max-width: 1440px;
    padding: 0 32px;
  }
}
@media only screen and (max-width: $mediaMedium) {
  .subscribeDetailMain {
    padding: 0 25px;
  }
  .subscribeDetailMain .subscribeDetailContent {
    flex-direction: column;
  }
  .subscribeDetailMain .subscribeDetailContent .subscribeLeftComponents,
  .subscribeDetailMain .subscribeDetailContent .subscribeRightComponents {
    width: 100%;
  }
}
@media only screen and (max-width: 550px) {
  .subscribeDetailMain {
    padding: 0 16px;
  }
}
