@import "../../../styles/dashboard/variables.scss";

@font-face {
  font-family: SourceSansPro;
  src: url("../../../../public/assets/fonts/SourceSansPro/SourceSans3.ttf");
  src: url("../../../../public/assets/fonts/SourceSansPro/SourceSans3.woff");
  src: url("../../../../public/assets/fonts/SourceSansPro/SourceSans3.woff2");
}

#BackToComponent {
  width: fit-content;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 16px;

  .titleSubmit {
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 8px;

    .icon {
      color: $secondary200;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        font-size: 20px;
      }
    }

    p {
      font-family: "SourceSansPro";
      font-size: 23px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      color: $secondary200;
    }
  }
  .line-01 {
    width: 1px;
    height: 30px;
    background: $secondary75;
    margin: 0 16px;
  }
  .IdNumberBox {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    p {
      color: $secondary100;
      font-family: "SourceSansPro";
      font-size: 23px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
    .icon {
      svg {
        color: #5e2bff;
        font-size: 24px;
      }
    }
  }
}

@media only screen and (max-width: 550px) {
  #BackToComponent {
    .titleSubmit {
      p {
        font-size: 19px;
        line-height: 20px;
      }
    }
    .IdNumberBox {
      p {
        font-size: 19px;
        line-height: 20px;
      }
      .icon {
        svg {
          font-size: 20px;
        }
      }
    }
  }
}
