@import "../../../../styles/dashboard/variables.scss";

#FilterPlainToggleButton {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  gap: 8px;

  border-radius: 24px;
  border: 1px solid $secondary50;
  background: $secondary25;

  user-select: none;

  cursor: pointer;
  p {
    color: $secondary300;
    font-size: 13px;
    font-weight: 600;
  }
  &.active {
    border: 1px solid $secondary500;
    background: $secondary500;
    p {
      color: $secondary0;
      font-size: 13px;
      font-weight: 600;
    }
    .icon {
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        font-size: 13px;
        color: $secondary0;
      }
    }
  }
}
