@import "../../../styles/dashboard/variables.scss";

#SubscribersProfileOrdersListMobile {
  min-height: 500px;
  width: 100%;

  .SubscribersOrderListMobileItems {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
    border-radius: $borderRadiusLarge;
  }
}
