@import "../../../../styles/dashboard/variables.scss";

#FilterDetailSearchModal {
  width: 500px;
  background-color: $white;
  border-radius: $borderRadiusLarge;
  overflow: hidden;

  .header {
    display: flex;
    padding: 16px 24px;
    justify-content: space-between;
    align-items: center;
    gap: 8px;

    .title {
      display: flex;
      align-items: center;
      gap: 8px;
      h2 {
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
      }
      .titleIcon {
        display: flex;
        svg {
          font-size: 16px;
          color: $secondary100;
        }
      }
    }
    .closeIcon {
      display: flex;
      cursor: pointer;
      padding: 5px;
      svg {
        font-size: 18px;
        color: $secondary200;
      }
    }
  }
  .content {
    width: 100%;
    min-height: 120px;
    max-height: 80vh;
    background-color: $white;
    border-bottom: 1px solid $secondary50;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow: auto;
    .inputGroup {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 24px;
      gap: 8px;

      border-bottom: 1px solid $secondary50;
      h3 {
        color: $secondary300;
        font-size: 16px;
        font-weight: 600;
      }
      .inputsContainer {
        width: 100%;
        display: flex;
        align-items: flex-start;
        gap: 10px;
      }
    }
  }
  .footer {
    padding: 16px 24px;
    display: flex;
    align-items: center;
    gap: 16px;
    width: 100%;
    #DButton {
      width: 100%;
      button {
        width: 100%;
      }
    }
    #DTextButton {
      width: 100%;
      button {
        width: 100%;
      }
    }
  }

  #DatepickerButton {
    width: 100%;
    background-color: $white;
    border: 1px solid $secondary100;
    height: 40px;
    border-radius: $borderRadius;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }
  .react-datepicker-wrapper {
    width: 100%;
  }
}
