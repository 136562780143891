@import "./../../../../../../styles/dashboard/variables.scss";

#ShareTrackingInfoStatus {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;

  .statusCard {
    display: flex;
    padding: 12px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 999px;
    p {
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
    }
  }

  &.confrim {
    .statusCard {
      background: rgba(253, 241, 219, 0.25);
      p {
        color: #f7b84b;
      }
    }
  }

  &.transit {
    .statusCard {
      background: rgba(215, 228, 252, 0.25);
      p {
        color: #3577f1;
      }
    }
  }

  &.complete {
    .statusCard {
      background: #fafffd;
      svg {
        color: #00b56c;
      }
      p {
        color: #00b56c;
      }
    }
  }

  h6 {
    color: #878a99;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;

    span {
      color: #212529;
    }
  }
}
