@import "./../../../../styles/dashboard/variables.scss";

.ShareNavMobile {
  display: none;
  position: absolute;
  left: -100vw;
  z-index: 101;
  width: 100vw;
  height: calc(100vh - 80px);
  margin-top: 80px;
  transition: all 0.3s ease-in-out;
  background: $secondary25;
  top: 0;
  opacity: 0;
  overflow-x: auto;
  box-shadow: 0px 10px 35px 0px rgba(0, 0, 0, 0.12);

  .ShareNavMobileContainer {
    width: 100%;
    height: 100%;
  }

  &.open {
    left: 0;
    opacity: 1;
  }
}
@media only screen and (max-width: $mediaLarge) {
  .ShareNavMobile {
    display: flex;
  }
}
