@import "./../../../../../styles/dashboard/variables.scss";

#ShareDetailContentTracking {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;

  .ShareDetailContentTrackingtitle {
    display: flex;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 4px;
    background: #f3f6f9;

    h3 {
      flex: 1 0 0;
      color: #212529;
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }
  }

  .ShareDetailContentTrackingContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;

    .ShareDetailContentTrackingInfo {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      align-self: stretch;
      gap: 12px;
    }
  }
}

@media only screen and (max-width: $mediaSmall) {
  #ShareDetailContentTracking {
    .ShareDetailContentTrackingContent {
      .ShareDetailContentTrackingInfo {
        flex-direction: column;
      }
    }
  }
}
