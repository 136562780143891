@import "../../../styles/dashboard/variables.scss";

@font-face {
  font-family: SourceSansPro;
  src: url("../../../../public/assets/fonts/SourceSansPro/SourceSans3.ttf");
  src: url("../../../../public/assets/fonts/SourceSansPro/SourceSans3.woff");
  src: url("../../../../public/assets/fonts/SourceSansPro/SourceSans3.woff2");
}
.verificationModalHandle {
  .verificationBox {
    display: flex;
    flex-direction: column;
    .verificationImage {
      display: flex;
      align-items: flex-start;
      position: relative;
      height: 257px;
      margin-bottom: 24px;
      .BackVerification {
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 8px;
        margin-top: 10px;
        .icon {
          height: 16px;
          svg {
            font-size: 16px;
            color: #4f575b;
          }
        }
        p {
          font-family: "SourceSansPro";
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;
          color: #1a2023;
        }
      }
      .globalImageBackground {
        width: 257px;
        height: 257px;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    .infoAlertVerify {
      display: flex;
      align-items: center;
      padding: 12px 16px;
      gap: 10px;
      border-radius: 4px;
      background-color: #eef2fa;
      margin-bottom: 24px;
      > div {
        color: #2e5aac;
      }
      .icon svg {
        font-size: 16px;
      }
      .text {
        font-family: "SourceSansPro";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
    }
  }
  .footer {
    .cancel {
      display: none !important;
    }
    .submit {
      width: 100%;
      background: #00b56c !important;
    }
  }

  .veriFyCodeBox {
    max-width: 340px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin: 0 auto;
    word-wrap: break-word;
    .text {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 12px;
      h2 {
        color: #212529;
        text-align: center;
        font-family: "SourceSansPro";
        font-size: 23px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
      }
      p {
        color: #878a99;
        text-align: center;
        font-family: "SourceSansPro";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        span {
          color: #405189;
        }
      }
    }
    .OTPVerifyBox {
      width: 100%;
    }
  }
}
.ChangeSomeContent {
  .footer {
    .submit {
      display: none !important;
    }
  }
  #OTPContainer .optWrapper > div input {
    width: 48px !important;
    height: 56px !important;
  }
  .SendAgainBox {
    #SendAgain {
      .content {
        display: flex !important;
        flex-direction: row;
        display: flex !important;
        flex-direction: row;
        gap: 8px;
        min-height: auto;
        justify-content: center;
        gap: 8px;
        .icon svg {
          color: #2e5aac;
        }
        p {
          color: #2e5aac;
        }
      }
    }
  }
}

@media only screen and (max-width: 550px) {
  .verificationModalHandle .verificationBox .verificationImage {
    height: 200px;
    .globalImageBackground {
      width: 170px;
      height: 170px;
      top: 24px;
    }
  }
}
