@import "../../../../../styles/dashboard/variables.scss";

#LoadBoardsDeliveredField {
  vertical-align: middle;
  padding: 12px 10px;
  .dropoffDate {
    color: $secondary200;
    font-size: 13px;
    font-weight: 600;
    line-height: 20px;
    &.est {
      color: $info;
      font-weight: 400;
    }
  }
  .pickupDate {
    color: $secondary100;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
  }
}
