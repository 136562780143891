@import "./../../../styles/dashboard/variables.scss";

#ShareSidebarHeader {
  height: 142px;
  width: 100%;

  display: flex;
  flex-direction: column;

  .title {
    margin-top: 16px;
    margin-bottom: 16px;

    padding: 0px 24px;
    h2 {
      color: #212529;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 40px; /* 200% */
    }
  }
  .stats {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 4px 16px;
    gap: 8px;
    background: linear-gradient(0deg, rgba(206, 240, 235, 0.25) 0%, rgba(206, 240, 235, 0.25) 100%),
      #fff;
    box-shadow: 0px 1px 2px 0px rgba(56, 65, 74, 0.15);

    .rowcontainer {
      display: flex;
      align-items: center;
      gap: 8px;

      .pin {
        svg {
          font-size: 24px;
          color: #00b56c;
        }
      }
      .arrow {
        svg {
          font-size: 24px;
          color: #878a99;
        }
      }
      .address {
        display: flex;
        align-items: center;
        gap: 8px;

        h4 {
          color: #212529;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          span {
            color: #2e5aac;
            cursor: pointer;
          }
        }
        svg {
          font-size: 16px;
          color: #848a8d;
        }
      }
      .desc {
        display: flex;
        align-items: center;
        gap: 8px;

        h5 {
          color: #4f575b;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
        }
        .dot {
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            font-size: 8px;
            color: #212529;
          }
        }
        h6 {
          color: #878a99;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
        }

        .orderCount {
          display: flex;
          align-items: center;
          gap: 4px;
          svg {
            font-size: 16px;
            color: #878a99;
          }
          h4 {
            color: "#212529";
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
          }
        }
      }
    }
  }
}

.SharepickUpAddressCountTooltipA {
  box-shadow: 0px 6.25px 20px 0px rgba(0, 0, 0, 0.13);
  background: $secondary0 !important;
  border-radius: $borderRadius;
  display: inline-flex;
  min-width: 100px;

  .list {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 6px;

    p {
      color: #212529;
      font-size: 14px;
    }
  }
}

.ShareTrackingTransportTooltipB {
  box-shadow: 0px 6.25px 20px 0px rgba(0, 0, 0, 0.13);
  background: $secondary0 !important;
  border-radius: $borderRadius;
  display: inline-flex;
  min-width: 100px;

  .list {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 6px;

    p {
      color: #212529;
      font-size: 14px;
    }
  }
}
