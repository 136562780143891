@import "./../../../styles/dashboard/variables.scss";

#ShareSidebarList {
  height: 100%;
  width: 100%;

  .orderList {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 12px 16px;

    max-height: calc(100vh - 142px - 81px - 12px);
    overflow-y: auto;
  }
}

@media only screen and (max-width: $mediaLarge) {
}
