@import "./../../styles/dashboard/variables.scss";

#ShareHeader {
  position: fixed;
  width: 100%;
  height: 80px;
  background-color: #fff;
  border-bottom: 1px solid #ced4da;
  align-items: center;
  display: flex;
  z-index: 9999;
  .ShareHeaderContainer {
    width: 100%;
    padding: 0px 28px 0px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;

    .ShareHeaderContainerLogo {
      .fullLogo {
        display: block;
      }
      .circularLogo {
        display: none;
      }
    }
  }
}

@media only screen and (max-width: $mediaLarge) {
  #ShareHeader {
    .ShareHeaderContainer {
      .ShareHeaderContainerLogo {
        .fullLogo {
          display: none;
        }
        .circularLogo {
          display: block;
        }
      }
    }
  }
}
