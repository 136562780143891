@import "../../../styles/dashboard/variables.scss";

@font-face {
  font-family: SourceSansPro;
  src: url("../../../../public//assets/fonts/SourceSansPro/SourceSans3.ttf");
  src: url("../../../../public//assets/fonts/SourceSansPro/SourceSans3.woff");
  src: url("../../../../public//assets/fonts/SourceSansPro/SourceSans3.woff2");
}

#SubscribeTitleComponent {
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;

  .icon {
    svg {
      color: $secondary100;
      font-size: 24px;
    }
  }
  .title {
    color: $secondary300;
    font-family: "SourceSansPro";
    font-size: 19px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }
}
