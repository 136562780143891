@import "./../../../styles/dashboard/variables.scss";

#ShareDetailFooter {
  display: flex;
  padding: 12px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid #ced4da;
  background: #fff;

  h2 {
    color: #212529;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }
  .buttons {
    display: flex;
    align-items: center;
    gap: 24px;
    align-self: stretch;

    .learnMore {
      width: 100%;
      display: flex;
      padding: 16px 24px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      flex: 1 0 0;
      border-radius: 4px;
      border: 1px solid #00b56c;
      background: #fafffd;

      p {
        color: #212529;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
    }
    .signup {
      width: 100%;
      display: flex;
      padding: 16px 24px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      flex: 1 0 0;
      border-radius: 4px;
      border: 1px solid #00b56c;
      background: #00b56c;

      p {
        color: #fff;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
    }
  }
}

@media only screen and (max-width: $mediaSmall) {
  #ShareDetailFooter {
    padding: 12px;
    .buttons {
      flex-direction: column;
      gap: 12px;
      .learnMore {
        padding: 12px;
      }
      .signup {
        padding: 12px;
      }
    }
  }
}
