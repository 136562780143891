@import "../../../../styles/dashboard/variables.scss";

#TableColumnWithSort {
  .TableColumnWithSortContainer {
    display: inline-flex;
    align-items: center;
    user-select: none;
    cursor: pointer;
    svg {
      margin-left: 4px;
      font-size: 16px;
    }
  }
}
