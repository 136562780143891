@import "../../../styles/dashboard/variables.scss";

@font-face {
  font-family: SourceSansPro;
  src: url("../../../../public/assets/fonts/SourceSansPro/SourceSans3.ttf");
  src: url("../../../../public/assets/fonts/SourceSansPro/SourceSans3.woff");
  src: url("../../../../public/assets/fonts/SourceSansPro/SourceSans3.woff2");
}

#SubscribeBidReqComponent {
  display: flex;
  padding: 16px 24px;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  width: 100%;
  border-radius: 8px;
  border: 1px solid $secondary50;
  background: #fff;
  .subscribeBidReqCard {
    width: 100%;
    .CardHeader {
      width: 100%;
      padding-bottom: 16px;
      border-bottom: 1px solid $secondary50;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;
    }
    .CardBodyMakeBid {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .text {
        font-family: "SourceSansPro";
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: $secondary300;
        margin-top: 10px;
      }
      .BidButton {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 48px;
        border-radius: 8px;
        background-color: #00b56c;
        font-family: "SourceSansPro";
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        color: $secondary0;
        margin-top: 22px;
        cursor: pointer;
      }
      #DButton {
        width: 100%;
        button {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 48px;
          border-radius: 8px;
          background-color: #00b56c;
          font-family: "SourceSansPro";
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          color: $secondary0;
          margin-top: 22px;
          cursor: pointer;
        }
      }
    }
    .cardBodyOffer {
      font-family: "SourceSansPro";
      background: #00b56c;
      width: 100%;
      border-radius: 12px;
      border: 1px solid #00b56c;
      .MyOffer {
        display: flex;
        flex-direction: column;
        .offerTop {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 6px 10px;
          h2 {
            color: $secondary0;
            line-height: 24px;
            font-size: 16px;
            font-weight: 600;
            font-family: "SourceSansPro";
          }
          .SentTime {
            color: $secondary0;
            line-height: 20px;
            font-size: 13px;
            font-weight: 400;
            font-family: "SourceSansPro";
          }
        }
        .offerBoxBottom {
          width: 100%;
          background: $secondary0;
          border-radius: 12px;
          position: relative;
          overflow: hidden;
          .BoxTop {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            gap: 10px;
            padding: 12px 16px;
            background: rgba(237, 249, 240, 0.5);
            border-bottom: 1px solid $secondary50;
            .imgAndPaid {
              display: flex;
              align-items: center;
              gap: 12px;
              .img {
                width: 48px;
                height: 48px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                background: $secondary0;
                border: 1px solid $secondary50;
                img {
                  width: 25px;
                  height: 25px;
                }
              }
              .PrePaid {
                h2 {
                  font-family: "SourceSansPro";
                  color: #00b56c;
                  font-size: 33px;
                  line-height: 36px;
                  font-weight: 600;
                }
                p {
                  font-family: "SourceSansPro";
                  color: $secondary200;
                  font-size: 16px;
                  font-weight: 400;
                  line-height: 24px;
                }
              }
            }
            .AwaitingBox {
              display: flex;
              align-items: center;
              gap: 4px;
              .icon {
                font-size: 20px;
                color: #b95000;
                height: 20px;
              }
              p {
                font-family: "SourceSansPro";
                color: #b95000;
                font-size: 13px;
                font-weight: 600;
                line-height: 20px;
              }
            }
          }
          .BoxBottom {
            display: flex;
            flex-wrap: wrap;
            gap: 8px;
            padding: 12px 16px;
            .InfoItemLeft {
              width: 30%;
              display: flex;
              flex-direction: column;
              gap: 8px;
              h4 {
                font-size: 13px;
                font-weight: 300;
                line-height: 20px;
                color: $secondary400;
                font-family: "SourceSansPro";
              }
            }
            .InfoItemRight {
              width: 68%;
              display: flex;
              flex-direction: column;
              gap: 8px;
              h2 {
                font-size: 16px;
                font-weight: 600;
                line-height: 20px;
                color: $secondary300;
                font-family: "SourceSansPro";
              }
            }
            .updateBidLink {
              width: 100%;
              cursor: pointer;
              display: flex;
              align-items: center;
              justify-content: center;
              height: 44px;
              margin-top: 10px;
              gap: 4px;
              border-radius: 8px;
              background: #eef2fa;
              border: 1px solid $info;
              .icon {
                color: $info;
                font-size: 16px;
              }
              p {
                font-family: "SourceSansPro";
                font-size: 14px;
                line-height: 20px;
                font-weight: 600;
                color: $info;
              }
            }
          }
        }
        .boxRelative {
          position: relative;
          padding-bottom: 50px;
        }
        .acceptedTextBox {
          padding: 0 12px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          background-color: #edf9f0;
          height: 45px;
          position: absolute;
          bottom: 0;
          left: 0;
          .text {
            margin-left: 8px;
            color: #287d3c;
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
            font-family: "SourceSansPro";
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $mediaLarge) {
  #SubscribeBidReqComponent
    .subscribeBidReqCard
    .cardBodyOffer
    .MyOffer
    .offerBoxBottom
    .BoxBottom
    .InfoItemLeft {
    width: 45%;
  }
  #SubscribeBidReqComponent
    .subscribeBidReqCard
    .cardBodyOffer
    .MyOffer
    .offerBoxBottom
    .BoxBottom
    .InfoItemRight {
    width: 52%;
  }
}

@media only screen and (max-width: $mediaMedium) {
  #SubscribeBidReqComponent {
    margin-bottom: 60px;
    .subscribeBidReqCard .CardBodyMakeBid {
      #DButton {
        position: fixed;
        bottom: 16px;
        width: 92%;
        margin: 0 auto;
        height: initial;
        left: 4%;
        button {
          font-size: 14px;
          min-height: 36px;
          height: initial;
        }
      }
    }
  }
}
@media only screen and (max-width: 550px) {
  #SubscribeBidReqComponent {
    padding: 16px;
    .subscribeBidReqCard .cardBodyOffer .MyOffer .offerBoxBottom .BoxTop .imgAndPaid .PrePaid h2 {
      font-size: 26px;
    }
    .subscribeBidReqCard .cardBodyOffer .MyOffer .offerBoxBottom .BoxBottom .InfoItemRight h2 {
      font-size: 14px;
    }
    .subscribeBidReqCard .cardBodyOffer .MyOffer .offerBoxBottom .BoxBottom .InfoItemLeft h4 {
      font-size: 12px;
    }
    .subscribeBidReqCard .cardBodyOffer .MyOffer .acceptedTextBox .text {
      font-size: 12px;
    }
  }
}
