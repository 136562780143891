@import "../../../../../styles/dashboard/variables.scss";

#LoadBoardsOrderField {
  vertical-align: middle;
  a {
    display: flex;
    padding: 12px 10px;

    color: $info;

    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }
  &.link {
    &:hover {
      background-color: $secondary25;
    }
  }
}
