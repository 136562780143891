@import "../../../../../styles/dashboard/variables.scss";

#LoadBoardsStatusField {
  vertical-align: middle;
  padding: 12px 10px;
  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    .status {
      display: inline-flex;
      align-items: center;
      padding: 4px 12px;
      gap: 10px;
      border-radius: 34px;
      p {
        font-size: 13px;
        font-weight: 600;
        line-height: 16px;
      }
      .dot {
        background-color: $secondary25;
        width: 8px;
        height: 8px;
        border-radius: 50%;
      }
    }
  }
}
