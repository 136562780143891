@import "../../../styles/dashboard/variables.scss";

#SubscriberPersonalInfo {
  width: 100%;
  justify-content: space-between;
  display: flex;
  align-items: flex-end;
  .subscriberPersonalInfoCont {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    margin-top: 24px;
    .personAvatar {
      width: 60px;
      height: 60px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .personInfo {
      display: flex;
      flex-direction: column;
      gap: 4px;
      .fullName h2 {
        color: $secondary200;
        font-family: "SourceSansPro";
        font-size: 19px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
      }
      .personContactsContainer {
        display: flex;
        align-items: center;
        gap: 24px;
        .personContacts {
          display: flex;
          flex-wrap: wrap;
          gap: 24px;
          .contactItem {
            display: flex;
            align-items: center;
            gap: 4px;
            width: fit-content;
            p,
            .icon {
              color: $secondary100;
              font-family: "SourceSansPro";
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px;
              display: flex;
              align-items: center;
            }
            #DIconButton {
              svg {
                color: $info;
                font-size: 13px;
              }
            }
          }
          .edit {
            flex-direction: row-reverse;
            margin-left: 12px;
            gap: 8px;
            cursor: pointer;
            p,
            .icon {
              color: $info;
              font-weight: 600;
            }
          }
        }
      }
    }
  }
  .editPreferenceBtn {
    #DButton {
      button {
        border: none;
        min-height: fit-content;
        min-width: fit-content;
        padding: 12px 16px;
        background: $violet;
        border-radius: 8px;
      }
    }
  }
}

@media only screen and (max-width: $mediaMedium) {
  #SubscriberPersonalInfo {
    flex-direction: column;
    gap: 12px;
    .subscriberPersonalInfoCont {
      flex-direction: column;
      .personInfo {
        .personContactsContainer {
          justify-content: space-between;
          align-items: flex-start;
          .personContacts {
            flex-direction: column;
            gap: 8px;
            .editButton {
              display: flex;
              justify-content: flex-end;
            }
          }
        }
      }
    }
    .editPreferenceBtn {
      width: 100%;
      #DButton {
        button {
          width: 100%;
        }
      }
    }
  }
}
