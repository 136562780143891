@import "../../../styles/dashboard/variables.scss";

@font-face {
  font-family: SourceSansPro;
  src: url("../../../../public//assets/fonts/SourceSansPro/SourceSans3.ttf");
  src: url("../../../../public//assets/fonts/SourceSansPro/SourceSans3.woff");
  src: url("../../../../public//assets/fonts/SourceSansPro/SourceSans3.woff2");
}

#SubscribeTransportComponent {
  display: flex;
  padding: 16px 24px;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  width: 100%;
  border-radius: 8px;
  border: 1px solid $secondary50;
  background: #fff;
  .subscribeTransportCard {
    width: 100%;
    .CardHeader {
      width: 100%;
      padding-bottom: 16px;
      border-bottom: 1px solid $secondary50;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;
    }
    .CardBody {
      margin-bottom: 10px;
      width: 100%;
      border-radius: 4px;
      border: 1px solid $secondary50;
      padding: 12px;
      .transportationContainer {
        width: 100%;
        height: auto;
        h2 {
          font-family: "SourceSansPro";
          margin-bottom: 12px;
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          color: #023e7d;
        }
        .TrackDataType {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          gap: 8px;
          margin-top: 12px;
          .TrItem {
            min-width: 160px;
            display: flex;
            flex-direction: column;
            gap: 4px;
            p {
              font-family: "SourceSansPro";
              font-size: 13px;
              font-weight: 300;
              line-height: 20px;
              color: $secondary400;
            }
            h3 {
              font-family: "SourceSansPro";
              font-size: 16px;
              font-weight: 600;
              line-height: 24px;
              color: $secondary300;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 550px) {
  #SubscribeTransportComponent {
    padding: 16px;

    .subscribeTransportCard .CardBody .transportationContainer .TrackDataType .TrItem {
      width: 100%;
    }
  }
}
