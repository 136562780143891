@import "../../../styles/dashboard/variables.scss";

#SubscribersFiltersMobile {
  display: none;
}

@media only screen and (max-width: $mediaMedium) {
  #SubscribersFiltersMobile {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    .leftContainer {
      display: flex;
      align-items: center;
      gap: 8px;
      #PreferencesFilter {
        .title {
          display: none;
        }
      }
      .filtersButton {
        display: flex;
        align-items: center;
        padding: 8px 12px;
        gap: 8px;

        border-radius: 32px;
        border: 1px solid $secondary50;
        color: $secondary100;
        cursor: pointer;
        .icon {
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            font-size: 16px;
          }
        }
        p {
          color: $secondary200;
          font-size: 14px;
          font-weight: 600;
        }
      }
    }
  }
}
