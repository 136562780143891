@import "./../../../../styles/dashboard/variables.scss";

#ShareSidebarListItem {
  padding: 12px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(56, 65, 74, 0.15);
  border: 1px solid #ced4da;
  transition: all 0.2s ease-in-out;

  &.active {
    border: 1px solid #3577f1;
  }
  .itemHeader {
    display: flex;
    align-items: flex-start;
    gap: 4px;
    overflow: hidden;
    flex-wrap: wrap;

    .trailerType {
      display: flex;
      padding: 4px 8px;
      align-items: center;
      gap: 4px;
      border-radius: 4px;
      border: 1px solid #e9ebec;

      svg {
        font-size: 16px;
        color: #878a99;
      }
      h4 {
        color: #4a5056;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
      }
      h5 {
        color: #878a99;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
      }
    }
    .transports {
      display: flex;
      padding: 4px 8px;
      align-items: center;
      border-radius: 4px;
      border: 1px solid #e9ebec;

      h4 {
        color: #4a5056;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        span {
          color: #3577f1;
        }
      }
    }
  }

  .itemBody {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 4px;
    flex: 1 0 0;

    .nostatusupdate {
      color: #878a99;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }

    .status {
      display: flex;
      align-items: center;
      gap: 4px;
      align-self: stretch;
      h4 {
        color: #878a99;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      svg {
        font-size: 16px;
      }
      h5 {
        color: #212529;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
    .text {
      color: #878a99;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      span {
        color: #405189;
      }
    }

    &.confrim {
      svg {
        color: #f7b84b;
      }
    }
    &.transit {
      svg {
        color: #3577f1;
      }
    }
    &.complete {
      svg {
        color: #0ab39c;
      }
    }
  }
}

.ShareSidebarListItemTransports {
  box-shadow: 0px 6.25px 20px 0px rgba(0, 0, 0, 0.13);
  background: $secondary0 !important;
  border-radius: $borderRadius;
  display: inline-flex;
  min-width: 100px;
  z-index: 10000;

  .list {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 6px;

    p {
      color: #212529;
      font-size: 14px;
    }
  }
}
