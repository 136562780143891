@import "../../../../../styles/dashboard/variables.scss";

#LoadBoardsPickupDateField {
  vertical-align: middle;
  padding: 12px 10px;
  .LoadBoardsPickupDateFieldcontent {
    min-width: 80px;
    p {
      color: $secondary100;
      font-size: 13px;
      font-weight: 400;
      line-height: 20px;
    }
    .date {
      color: $secondary200;
      font-size: 13px;
      font-weight: 600;
      line-height: 20px;
    }
  }
}
