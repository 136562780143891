@import "./../../../../styles/dashboard/variables.scss";
#ShareLanguage {
  cursor: pointer;
  position: relative;
  .ShareLanguageButton {
    display: flex;
    gap: 4px;
    align-items: center;
    padding: 12px 0;
    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        color: $secondary500;
        font-size: 22px;
      }
    }
    p {
      color: $secondary500;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
    }
  }
  .ShareLanguageContent {
    display: none;
    right: 50%;
    transform: translateX(50%);
    width: auto;
    position: absolute;
    overflow: hidden;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 102;
    border-radius: $borderRadiusLarge;
    #ShareLanguageItem {
      text-align: center;
      transition: all 0.3s ease-in-out;
      padding: 12px;
      cursor: pointer;
      color: $primaryContent;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      background-color: $secondary0;
      &:hover {
        background-color: $primary50;
      }
    }
  }
  &:hover .ShareLanguageContent {
    display: block;
  }
}
