@import "./../../styles/dashboard/variables.scss";

#ShareDetail {
  display: flex;
  flex-direction: column;
  gap: 16px;

  background-color: #f3f3f9;
  padding: 20px 60px 0px 60px;
  height: calc(100vh - 80px);
  overflow: hidden;
  overflow-y: auto;
}

@media only screen and (max-width: $mediaLarge) {
  #ShareDetail {
    padding: 12px;
  }
}
