@import "./../../../../../../styles/dashboard/variables.scss";

#ShareRoadprogressContainer {
  //   overflow: hidden;
  height: 10px;
  width: 100%;
  border-radius: 8px;
  background: $secondary50;
  margin-top: 15px;

  .color {
    border-radius: 8px;
    width: 100%;
    height: 100%;
    background-color: $primary700;
    position: relative;

    .dot {
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: $secondary50;
      position: absolute;
      right: 3px;
      top: 50%;
      transform: translateY(-50%);
    }
    .small {
      position: absolute;
      right: -10px;
      top: 50%;
      transform: translateY(-50%);
    }
    .percentage {
      position: absolute;

      top: -20px;
      color: #00b56c;
      font-size: 11px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;

      &.small {
        right: -10px;
      }
      &.large {
        right: 3px;
      }
    }
  }
}
