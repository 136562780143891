@import "../../../../styles/dashboard/variables.scss";

#ShareBurgerIcon {
  display: none;
  cursor: pointer;
  .bar1,
  .bar2,
  .bar3 {
    width: 30px;
    height: 5px;
    background-color: $primaryBrand;
    margin: 5px 0;
    transition: 0.4s;
    border-radius: 2px;
  }
  &.change {
    .bar1 {
      transform: translate(0, 10px) rotate(-45deg);
    }
    .bar2 {
      opacity: 0;
    }
    .bar3 {
      transform: translate(0, -10px) rotate(45deg);
    }
  }
}

@media only screen and (max-width: $mediaLarge) {
  #ShareBurgerIcon {
    display: inline-block !important;
  }
}
