@import "./../../../../../styles/dashboard/variables.scss";

#ShareDetailContentDetails {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;

  .ShareDetailContentDetailstitle {
    display: flex;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 4px;
    background: #f3f6f9;

    h3 {
      flex: 1 0 0;
      color: #212529;
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }
  }

  .ShareDetailContentDetailscards {
    display: flex;
    padding: 8px 12px;
    align-items: flex-start;
    align-content: flex-start;
    gap: 12px;
    align-self: stretch;
    flex-wrap: wrap;
    border-radius: 4px;
    border: 1px solid #e9ebec;

    .ShareDetailContentDetailscardsLine {
      width: 1px;
      height: 44px;
      background: #e9ebec;
    }

    .ShareDetailContentDetailsitem {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 4px;
      flex: 1 0 0;

      .ShareDetailContentDetailsitemicon {
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          font-size: 24px;
          color: #878a99;
        }
      }
      .ShareDetailContentDetailsitemtext {
        h5 {
          color: #212529;
          font-size: 13px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;

          &.locations {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 4px;
            cursor: pointer;

            span {
              display: flex;
              align-items: center;
              justify-content: center;

              svg {
                font-size: 16px;
                color: #405189;
              }
            }
          }
        }
      }
    }
  }
}

.ShareDetailContentDetailsRouteFieldTooltip {
  box-shadow: 0px 6.25px 20px 0px rgba(0, 0, 0, 0.13);
  background: $secondary0 !important;
  border-radius: $borderRadius;
  display: inline-flex;
  min-width: 200px;
  .RouteFieldContent {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 12px 8px;
    .routeContainer {
      display: flex;
      align-items: center;
      gap: 8px;
      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          font-size: 14px;
        }
      }
      p {
        color: $secondary300;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
    }
  }
}

@media only screen and (max-width: $mediaSmall) {
  #ShareDetailContentHeader {
  }
}
