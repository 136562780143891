@import "../../../../styles/dashboard/variables.scss";

#SubscribersFiltersMobileFiltersModal {
  min-width: 300px;
  max-width: 500px;
  background-color: $white;
  border-radius: $borderRadiusLarge;
  overflow: hidden;
  .header {
    display: flex;
    padding: 16px 24px;
    justify-content: space-between;
    align-items: center;
    gap: 8px;

    .title {
      display: flex;
      align-items: center;
      gap: 8px;
      h2 {
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
      }
      .titleIcon {
        display: flex;
        svg {
          font-size: 16px;
          color: $secondary100;
        }
      }
    }
    .closeIcon {
      display: flex;
      cursor: pointer;
      padding: 5px;
      svg {
        font-size: 18px;
        color: $secondary200;
      }
    }
  }
  .content {
    width: 100%;
    min-height: 120px;
    max-height: 80vh;
    background-color: $white;
    border-bottom: 1px solid $secondary50;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow: auto;
    .sectionContainer {
      display: flex;
      padding: 16px;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;

      border-bottom: 1px solid $secondary50;
      .title {
        display: flex;
        align-items: center;
        gap: 8px;
        h3 {
          color: $secondary500;
          font-size: 16px;
          font-weight: 600;
        }
      }
      .filterContent {
        width: 100%;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 8px;
        .customRoute {
          display: flex;
          align-items: center;
          padding: 8px 12px;
          gap: 8px;

          border-radius: 24px;
          border: 1px solid $secondary50;
          background: $secondary25;

          user-select: none;

          cursor: pointer;
          p {
            color: $secondary300;
            font-size: 13px;
            font-weight: 600;
          }
          .icon {
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
              font-size: 13px;
            }
          }
          &.active {
            border: 1px solid $secondary500;
            background: $secondary500;
            p {
              color: $secondary0;
              font-size: 13px;
              font-weight: 600;
            }
            .icon {
              display: flex;
              align-items: center;
              justify-content: center;

              svg {
                font-size: 13px;
                color: $secondary0;
              }
            }
          }
        }
        #DDatepickerButton {
          width: 100%;
          background-color: $white;
          border: 1px solid $secondary100;
          height: 40px;
          border-radius: $borderRadius;
          padding-left: 10px;
          padding-right: 10px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;
        }
        .react-datepicker-wrapper {
          width: 100%;
        }
      }
    }

    // .inputGroup {
    //   display: flex;
    //   flex-direction: column;
    //   align-items: flex-start;
    //   padding: 24px;
    //   gap: 8px;

    //   border-bottom: 1px solid $secondary50;
    //   h3 {
    //     color: $secondary300;
    //     font-size: 16px;
    //     font-weight: 600;
    //   }
    //   .inputsContainer {
    //     width: 100%;
    //     display: flex;
    //     align-items: flex-start;
    //     gap: 10px;
    //   }
    // }
  }
  .footer {
    padding: 16px 24px;
    display: flex;
    align-items: center;
    gap: 16px;
    width: 100%;
    #DButton {
      width: 100%;
      button {
        width: 100%;
      }
    }
    #DTextButton {
      width: 100%;
      button {
        width: 100%;
      }
    }
  }
}
